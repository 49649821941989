#root {
    padding-top: 1em;
    background-color: #faf8f4;
}
body,
html {
    background-color: #faf8f4;
    font-family: "Source Sans Pro", sans-serif;
}
body.bg-danger {
    #root,
    .container,
    .row,
    .col {
        background-color: #dc3545 !important;
    }
}
.list-group-item-action {
    cursor: pointer;
}
#formhack {
    display: none;
    visibility: hidden;
}
header {
    user-select: none;
}
